<template>
  <div class="allViewComments">
    <div class="noData" v-show="commentData.length==0">
      <img src="../../images/noData.png" />
      <p>暂无评价数据</p>
    </div>
   <div class="hoveData">
     <div class="allViewCommentsList" v-for="v in commentData":key="v.userPhone">
       <img :src="v.headPic">
       <div class="allViewCommentsListContent">
         <div class="allViewCommentsListTop">
           <div>
             <h3>{{v.userPhone.substring(0,3)+"*****"+v.userPhone.substring(8)}}</h3>
             <p>租期：{{ v.startRentDate |dataFormat }}-{{v.expireDate |dataFormat}}</p>
           </div>
           <div>
             {{v.createTime|dataFormat}}
           </div>
         </div>
         <div class="allViewCommentsListBottom">
           {{v.evaluate}}
         </div>
       </div>
     </div>
   </div>
  </div>
</template>

<script>
export default {
  name: "allViewComments",
  data(){
    return{
      commentData:[
      //     {
      //   img:require('../../images/huace.png'),
      //   phone:15282047902,
      //   duration:7,
      //   date:'2021.10.09',
      //   txt:'方便快捷，机器很快到手。性价比高！方便快 捷，机器很快到手。性价比高！'
      // },
      //   {
      //     img:require('../../images/huace.png'),
      //     phone:15282047802,
      //     duration:7,
      //     date:'2021.10.09',
      //     txt:'方便快捷，机器很快到手。性价比高！方便快 捷，机器很快到手。性价比高！'
      //   }
        ]
    }
  },
  methods:{
    getEvaluates(){
      console.log(this.$route.params.evaluates);
      this.commentData=this.$route.params.evaluates
    }
  },
  created() {
    this.getEvaluates()
  }
}
</script>

<style lang="less" scoped>
.allViewComments{
  background: #F6F6F6;
  height: 100%;
  padding-top: 0.33rem;
  .hoveData{
    padding-bottom: 100px;
    .allViewCommentsList{
      display: flex;
      background: #FFFFFF;
      border-radius: 0.28rem;
      padding: 0.44rem 0.69rem 0.43rem 0.46rem;
      margin-bottom: 0.28rem;
      img{
        width: 1.11rem;
        height: 1.11rem;
        border-radius:50% ;
      }
      .allViewCommentsListContent{
        flex: 1;
        .allViewCommentsListTop{
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.36rem;
          div:nth-child(1){
            h3{
              font-size: 0.36rem;
              font-family: xiaocheng;
              font-weight: 500;
              color: #333333;
              margin-bottom: 0.26rem;
            }
            p{
              font-size: 0.31rem;
              font-family: xiaocheng;
              font-weight: 400;
              color: #666666;
            }
          }
          div:nth-child(2){
            font-size: 0.33rem;
            font-family: xiaocheng;
            font-weight: 400;
            color: #666666;
          }
        }
        .allViewCommentsListBottom{
          font-size: 0.39rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #333333;
          line-height: 0.61rem;
        }
      }
    }
  }
  .noData{
    text-align: center;
    margin-top: 50%;
    img{
      width: 5.56rem;
      margin-bottom: 0.56rem;
    }
    p{
      font-size: 0.36rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
